import React, { useEffect } from 'react';
import AOS from 'aos';
import testimonials from '../images/banner/testimonials.png';
import TRIFORCE from '../images/TRIFORCE logo.jpg';
import TAP from '../images/TAP logo.jpg';
import KHATRI from '../images/IMRAN KHATRI logo.jfif';
import stanger from '../images/stanger.png';
import turnip from '../images/turnip.png';



export default function Testimonials() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
    
    <section className="banner">
            <div className="bannerimg">
                <img src={testimonials} className="img-responsive lazy" />
                <div className="bnr-ovrlay"></div>
            </div>
            <div className="page-title">
                <h2>TESTIMONIALS</h2>
            </div>
        </section>

        <section className="section agreement-section-top clearfix">
            <div className="container clearfix">
                <div className="row">
                    <div className="col4 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main testimonials-position-spaceing">
                            <div className="testimonials-marks-main-services-text ">
                                <div className="testimonials-images-logo">
                                    <img src={TRIFORCE} alt="" />
                                </div>
                                <div className="testimonial-heading-section-area">
                                   
                                    <p>
                                        "A. Mukherjee & Co. has been of great assistance to us in drafting and
                                        negotiating
                                        key film rights acquisition agreements. Their day to day advice on critical
                                        questions of Intellectual Property in our line of business is invaluable. We
                                        have
                                        found them to be patient, helpful, responsive and thoroughly professional".
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col4 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main testimonials-position-spaceing">
                            <div className="testimonials-marks-main-services-text testimonials-center-items ">
                                <div className="testimonials-images-logo">
                                    <img src={TAP} alt="" />
                                </div>
                                <div className="testimonial-heading-section-area">
                                 
                                    <p>
                                        "We highly recommend AMAC for their services - especially in IP and Contracts.
                                        They advise us regularly on a variety of agreements and transactions and are
                                        great at providing quick and effective solutions. Best of all, they are very
                                        prompt and friendly."
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col4 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main testimonials-position-spaceing">
                            <div className="testimonials-marks-main-services-text ">
                                <div className="testimonials-images-logo">
                                    <img src={KHATRI} alt="" />
                                </div>
                                <div className="testimonial-heading-section-area">
                                  
                                    <p>
                                        "I was introduced to A. Mukherjee & Co. through a friend, for vetting a Media
                                        Production Contract for an ad campaign featuring a famous Swiss watch
                                        conglomerate. Not only did the firm do an excellent job of covering each
                                        loophole in the contract, they also took the time to walk me through all the
                                        tricky aspects of the deal and advised me till the deal was closed. I highly
                                        recommend them and wish them the best!"
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col4 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main testimonials-position-spaceing">

                            <div className="testimonials-marks-main-services-text testimonials-center-items ">
                                <div className="testimonials-images-logo">
                                    <img src={stanger} alt="" />
                                </div>
                                <div className="testimonial-heading-section-area">
                                   
                                    <p>
                                        “We are very satisfied with the professional support we receive from AMAC
                                        regarding TRADE MARK protection and action against counterfeiters in different
                                        markets. Consulting with AMAC is an imperative for us before taking any major
                                        business decision. We highly recommend them.”
                                    </p>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="col4 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main testimonials-position-spaceing">

                            <div className="testimonials-marks-main-services-text testimonials-center-items ">
                                <div className="testimonials-images-logo">
                                    <img src={turnip} alt="" />
                                </div>
                                <div className="testimonial-heading-section-area">
                                   
                                    <p>
                                        "I have consulted A. Mukherjee & Co. for several TRADE MARKs and copyrights,
                                        incl. TRADE MARK disputes. They take initiative and have timely, thorough and
                                        precise communication that brings the best results. I highly recommend them for
                                        all IPR matters."
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
