import React from 'react';
import aboutusBanner from '../images/banner/about-us.jpg';
import team4  from '../images/team4.png';
import team3  from '../images/team3.png';

export default function Aboutus() {
   
  return (<>
  <section className="banner">
      <div className="bannerimg">
        <img src={aboutusBanner} className="img-responsive lazy" />
        <div className="bnr-ovrlay"></div>
      </div>
      <div className="page-title">
        <h2>ABOUT US</h2>
      </div>
    </section>
  <section className="section about-section abtusbg clearfix">
      <div className="container clearfix">
        <div className="row">
          <div className="col12 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="about-left-main" style={{ backgroundColor: "#1f295ca1" }}>
              <p>Founded in 2018, A. Mukherjee & Co. (AMAC) initially started as a one-man show. Although, over the years,
                AMAC has functioned as a Boutique Firm primarily focusing on the field of Intellectual Property Law, its
                capabilities have significantly broadened since.
                <br/>
                <br/>
                The professionals who, over the course of time, have come to be associated with AMAC are individuals of diverse
                professional experience, educational backgrounds and interests. This diversity of talent - be it engineers or lawyers
                - lends AMAC a significant edge and enables it to approach complex legal issues with confidence and creativity.
                <br/>
                <br/>
                At AMAC, we have established systems for approaching different cases and equal diligence is lent to every matter,
                irrespective of their degree of seriousness or urgency. We keep our clients informed at every step, provide estimated
                costs and projected outcomes well in advance, bearing in mind the specific requirements of the clients, at all times.
                <br/>
                <br/>
                AMAC is privileged to have an extensive network of international Associates in almost all major jurisdictions and
                no jurisdiction is out of bounds, as far as legal services are concerned.
                <br/>
                <br/>
                We are headquartered at Kolkata – the “Cultural Capital” of India and an important historical epicentre, as the High
                Court at Calcutta was, during the days of the British Raj, the Supreme seat of Judicature in the country. Presently,
                the Kolkata Patent Office is the principal office for Patent and Design Registration in India. We also have
                professional associates at every major city in India with access to every major Court and Tribunal as well as the
                Supreme Court.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section about-section clearfix">
      <div className="container clearfix">
        <div className="textinr about-heading">
          <h1 className="maintitle">OUR TEAM </h1>
          <div className="line"></div>
        </div>
        <div className="row justify-content-center">
          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust">
              <div className="patents-main-services-text our-team-center-area">
                <img src={team4} alt=""/>
                <p>
                  ARUNAVA MUKHERJEE
                </p>
                <p className="team-degree">
                  Co-Founder & Managing Partner<br/><span>(B.Sc, B.Com, LLB, Advocate)</span>
                </p>
              </div>
            </div>


          </div>


          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust about-right-main">

              <div className="patents-main-services-text our-team-center-area">
                <img src={team3} alt="" />
                <p> 
                  RONOJOY BASU
                </p>
                <p className="team-degree">
                  Co-Founder & Partner<br/><span>(LLM, BBA LLB, Advocate)</span>
                </p>
              </div>
            </div>

          </div>

        </div>

        <div className="row justify-content-center">

          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust">

              <div className="patents-main-services-text our-team-center-area ">


                <p>
                  DR. SWAPAN K. MUKHOPADHYAY<br/><span>(Patent Consultant)</span>
                </p>
              </div>
            </div>

          </div>

          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust about-right-main">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  DEBASHISH BASU <br/><span>(Engineer and Patent Consultant)</span>
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  DEEPAK BANDYOPADHYAY <br/><span>(Engineer and Patent Consultant)</span>
                </p>
              </div>
            </div>


          </div>

          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust about-right-main">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  SUMAN K. DAS<br/><span>(B.Sc., Patent Agent)</span>
                </p>
              </div>
            </div>

          </div>
        </div>


        <div className="row justify-content-center">
          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  KAMESH GADIA<br/><span>(LLB, Patent Agent)</span>
                </p>
              </div>
            </div>


          </div>

          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust about-right-main">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  ANUPAM DAS ADHIKARI<br/><span>(LLB, Head of Litigation)</span>
                </p>
              </div>
            </div>

          </div>

        </div>

        <div className="row justify-content-center">
          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  DINESH PATWARI <br/><span>(B.Com, LLB)</span>
                </p>
              </div>
            </div>


          </div>

          <div className="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
            <div className="patents-left-main our-team-height-adjust about-right-main">

              <div className="patents-main-services-text our-team-center-area">

                <p>
                  JEETEN K. DHAL <br/><span>(B.Com, LLB)</span>
                </p>
              </div>
            </div>

          </div>








        </div>







      </div>
    </section>

</>)
}
