import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"; 
import AOS from 'aos';
import logoWhite from '../images/logo-white.png';
import homeBanner from '../images/banner/home.png';
import team1 from '../images/team1.png';
import team2 from '../images/team2.png'
import about1 from '../images/home-about-us.png'
import whatwedo from '../images/home-what-we-do.png'

export default function Home() {
  
  useEffect(() => {
    AOS.init()
  },[])
 
  return (
    <>
     <section className="banner hmbanner">
      <div className="bannerimg">
        <img src={homeBanner} className="img-responsive lazy" />
        <div className="bnr-ovrlay"></div>
        <div class="logo"><Link to="/"><img src={logoWhite} className="img-responsive lazy" /></Link></div> 
      </div>
    </section>
    <section className="section bnrtext">
      <div className="container">
        <div className="textinr">
         
          <h1 className="maintitle">Protecting Innovations Internationally
            <br />
            Since 2018
          </h1>

          <div className="line"></div>
        </div>
      </div>

    </section>

    <section className="section aboutus bg-blue">
      <div className="container">
        <div className="row cstmrw">
          <div className="col6 col-xs12" data-aos="fade-right" data-aos-duration="1500">
            <div className="abtimgwrap">
              <div className="imgbgshade"></div>
              <div className="aboutimg">
                <img src={about1} className="img-responsive lazy" />
              </div>
            </div>

          </div>
          <div className="col6 col-xs12" data-aos="fade-left" data-aos-duration="1500">
            <div className="secontnt abtcont">
              <h2 className="sectitle white">About Us</h2>
              <p className="para white">“At AMAC, we have established systems for approaching different cases and equal
                diligence is lent to every matter, irrespective of their degree of urgency”</p>
              <Link to="/about" className="secbtn">Read More <i className="fa fa-angle-right white"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section whtwedo">
      <div className="container">
        <div className="row cstmrw">
          <div className="col6 col-xs12" data-aos="fade-left" data-aos-duration="1500">
            <div className="secontnt abtcont">
              <h2 className="sectitle blue">What We Do</h2>
              <p className="para black">Learn more about our company services and why we may be a good fit for your
                situation. </p>
              <Link to="/intellectual-property" className="secbtn blue">Services <i className="fa fa-angle-right black"></i></Link>
            </div>
          </div>
          <div className="col6 col-xs12" data-aos="fade-right" data-aos-duration="1500">
            <div className="abtimgwrap">
              <div className="imgbgshade"></div>
              <div className="aboutimg">
                <img src={whatwedo} className="img-responsive lazy" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>


    <section className="section teamsec bg-blue">
      <div className="container">
        <div className="hdrtxt">
          <h2 className="sectitle white">Meet Our Team</h2>
        </div>
        <div className="teaminr">
          <div className="row teamrw">

            <div className="col4 col-xs12 col" data-aos="fade-right" data-aos-duration="1500">
              <div className="teamimgwrap">
                <div className="teamimg">
                  <img src={team1} className="img-responsive lazy" />
                </div>
                <div className="team-con">
                  <p>
                    ARUNAVA MUKHERJEE
                  </p>
                  <p className="team-degree">
                    Co-Founder & Managing Partner<br/><span>(B.Sc, B.Com, LLB, Advocate)</span>
                  </p>
                </div>
              </div>

            </div>
            <div className="col4 col-xs12 col" data-aos="fade-up" data-aos-duration="1500">
              <div className="teamimgwrap">
                <div className="teamimg">
                  <img src={team2} className="img-responsive lazy" />
                </div>
                <div className="team-con">
                  <p>
                    RONOJOY BASU
                  </p>
                  <p className="team-degree">
                    Co-Founder & Partner<br/><span>(LLM, BBA LLB, Advocate)</span>
                  </p>
                </div>
              </div>

            </div>
            

          </div>
        </div>

      </div>
    </section>


    <section className="section entprsname bnrtext">
      <div className="container">
        <div className="textinr">
          <h1 className="maintitle" data-aos="fade-up" data-aos-duration="1500">A. MUKHERJEE & CO.</h1>
          <h5 className="subtitle-small" data-aos="fade-up" data-aos-duration="1500">ADVOCATES, PATENT & TRADE MARK
            ATTORNEYS
            </h5>

        </div>
      </div>
    </section>

    </>
  )
}
