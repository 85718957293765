import React, { useEffect } from 'react';
import AOS from 'aos';
import domain from '../images/banner/domain.png';

export default function Domain() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
    
    <section class="banner">
            <div class="bannerimg">
                <img src={domain} class="img-responsive lazy" />
                <div class="bnr-ovrlay"></div>
            </div>
            <div class="page-title">
                <h2>DOMAIN NAME DISPUTES</h2>
            </div>
        </section>
        <section class="section domain-section clearfix">
            <div class="container clearfix">
                <div class="row justify-content-between">
                    <div class="col12 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                            <p class="white">
                                The Internet has grown rapidly over the last decade, not only as a source of information
                                but also as a place of business. This has thrown up new challenges for brand owners in
                                the form of Cybersquatting. Cybersquatting or Domain Name Squatting, in simple words, is
                                the unauthorised registration and use of a domain name, comprising of a mark that is
                                either similar or identical to an existing mark and/or domain name.
                            </p>
                            <p class="white">
                                We regularly advise our clients on strategic acquisition of Domain Names and carry out
                                Domain Name renewals. We have initiated several domain name complaints with the Internet
                                Corporation for Assigned Names and Numbers (ICANN), the National Internet Exchange of
                                India (NIXI) and WIPO.
                            </p>
                            <p class="white">
                                We also have in depth experience in Domain Name litigation at various courts, where we have successfully obtained cancellation or suspension of Domain Name orders.
                            </p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
