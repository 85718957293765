import React, { useEffect } from 'react';
import AOS from 'aos';
import teams from '../images/banner/our-teams-img2.jpg';
import team4 from '../images/team4.png';
import team3 from '../images/team3.png';

export default function Ourteam() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
   <section class="banner">
            <div class="bannerimg">
                <img src={teams} class="img-responsive lazy" />
                <div class="bnr-ovrlay"></div>
            </div>
            <div class="page-title">
                <h2>OUR TEAM</h2>
            </div>
        </section>
        

        <section class="section about-section clearfix">
            <div class="container clearfix">
              <div class="row justify-content-center">

                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust">
                            <div class="patents-main-services-text our-team-center-area"> 
                                <img src={team4} alt=""  />
                                <p >
                                    ARUNAVA MUKHERJEE 
                                </p>
                                <p class="team-degree">
                                    Co-Founder & Managing Partner<br/><span>(B.Sc, B.Com, LLB, Advocate)</span>
                                </p>
                            </div>
                        </div>


                    </div>


                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust about-right-main">

                            <div class="patents-main-services-text our-team-center-area"> 
                                 <img src={team3} alt="" />
                                <p>
                                    RONOJOY BASU
                                </p>
                                <p class="team-degree">
                                    Co-Founder & Partner<br/><span>(LLM, BBA LLB, Advocate)</span>
                                </p>
                            </div>
                        </div>

                    </div>
                   
                    </div>

                    <div class="row justify-content-center">
                  
                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust">

                            <div class="patents-main-services-text our-team-center-area "> 
                               
                               
                                <p>
                                    DR. SWAPAN K. MUKHOPADHYAY<br/><span>(Patent Consultant)</span> 
                                </p>
                            </div>
                        </div>

                    </div>

                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust about-right-main">

                            <div class="patents-main-services-text our-team-center-area"> 
                               
                                <p>
                                    DEBASHISH BASU <br/><span>(Engineer and Patent Consultant)</span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust">

                            <div class="patents-main-services-text our-team-center-area"> 
                               
                                <p>
                                    DEEPAK BANDYOPADHYAY <br/><span>(Engineer and Patent Consultant)</span>
                                </p>
                            </div>
                        </div>


                    </div>

                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust about-right-main">

                            <div class="patents-main-services-text our-team-center-area">
                               
                                <p>
                                    SUMAN K. DAS<br/><span>(B.Sc., Patent Agent)</span> 
                                </p>
                            </div>
                        </div>

                    </div>
                    </div>

                  
                    <div class="row justify-content-center">
                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust">

                            <div class="patents-main-services-text our-team-center-area"> 
                    
                                <p>
                                    KAMESH GADIA<br/><span>(LLB, Patent Agent)</span> 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust about-right-main">
                            <div class="patents-main-services-text our-team-center-area">
                                <p>
                                    ANUPAM DAS ADHIKARI<br/><span>(LLB, Head of Litigation)</span>   
                                </p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="row justify-content-center">
                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust">
                            <div class="patents-main-services-text our-team-center-area">
                                <p>
                                    DINESH PATWARI <br/><span>(B.Com, LLB)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col4 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div class="patents-left-main our-team-height-adjust about-right-main">
                            <div class="patents-main-services-text our-team-center-area"> 
                                <p>
                                    JEETEN K. DHAL <br/><span>(B.Com, LLB)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
