import React, { useEffect } from 'react';
import AOS from 'aos';
import information from '../images/banner/register-now-application-information-concept.jpg';

export default function Incorporetion() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
     <section className="banner">
        <div className="bannerimg">
            <img src={information} className="img-responsive lazy" />
            <div className="bnr-ovrlay"></div>
        </div>
        <div className="page-title">
          <h2>INCORPORATION & REGISTRATION</h2>
      </div>
    </section>
        <section className="section agreement-section-top clearfix">
            <div className="container clearfix">
                <div className="row">
                    <div className="col12 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main incorporation-position-spaceing">
                            <div className="agreement-marks-main-services-text ">
                                <p>
                                    We provide advisory and services pertaining to (but not limited to) Company
                                    Incorporation and Registration, LLP formation and Registration, MSME (Ministry of
                                    Micro, Small and Medium Enterprises) Registration, drafting Partnership Deeds,
                                    Establishing and Registration of Trusts, Societies, and setting up Educational
                                    Institutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
