
import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import $ from "jquery";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home  from "./components/Home";
import About  from "./components/Aboutus";
import IntellectualProperty  from "./components/IntellectualProperty";
import Iitigation  from "./components/Iitigation";
import Agreements  from "./components/Agreements";
import Domain  from "./components/Domain";
import MediaEntertainment  from "./components/MediaEntertainment"; 
import Testimonials  from "./components/Testimonials";
import Incorporetion  from "./components/Incorporetion";
import Contact   from "./components/Contact";
import Career  from "./components/Career";
import Ourteam  from "./components/Ourteam";
import AppLayout from  "./components/layout";


function App() {

  return (
  <>
  <div className="wrapper">
  <BrowserRouter >
        <Routes>
        <Route element={<AppLayout />} >
            <Route path="/" element={<Home />} />
            <Route path="/index" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-team" element={<Ourteam />} />
            <Route path="/intellectual-property" element={<IntellectualProperty />} />
            <Route path="/litigation" element={<Iitigation />} />
            <Route path="/agreements" element={<Agreements />} />
            <Route path="/domain" element={<Domain />} />
            <Route path="/media-entertainment" element={<MediaEntertainment />} />
            <Route path="/domain" element={<Domain />} />
            <Route path="/incorporetion" element={<Incorporetion />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
        </Route>
        </Routes>
      </BrowserRouter>
    </div>
  </>
  );
}

export default App;
