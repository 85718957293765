import React, { useEffect, Component  } from 'react';
import AOS from 'aos';
import Frame from 'react-iframe';
import contactbnnaer from '../images/banner/negotiating-business-image-businesswomen-handshake-happy-with-work-business-woman-she-is-enjoying-with-her-workmate-handshake-gesturing-people-connection-deal-concept.jpg';
import email from '../images/email.png';

export default function Contact() {
    useEffect(() => {
        AOS.init();
      },
      [])
      
  return (
    <>
 <section className="banner">
            <div className="bannerimg">
                <img src={contactbnnaer} className="img-responsive lazy"/>
                <div className="bnr-ovrlay"></div>
            </div>
            <div className="page-title">
                <h2>CONTACT US</h2>
            </div>
        </section>
        <section className="section contact">
            <div className="container">
                <div className="hdrtxt">
                    <h2 className="sectitle blue">Get In Touch</h2>
                </div>
                    <div className="row">
                        <div className="col4 col-xs12 col" data-aos="fade-right" data-aos-duration="1500">
                            <div className="conctitem">
                                <div className="icon">
                                    <img src={email} className="img-responsive lazy" />
                                </div>
                                <h3>PHONE & EMAIL</h3>
                                <p><i className="fa fa-phone"></i> <a href="tel:+913322412246">+91 33 2241 2246</a>, <a href="tel:+918240103986">+918240103986</a></p><br/>
                                <p><i className="fa fa-fax"></i> <a href="fax:03322412248">+91 33 2241 2248</a></p><br/>
                                <p><i className="fa fa-envelope"></i><a href="mailto:info@amukherjeeip.com">info@amukherjeeip.com</a></p>
                            </div>
                        </div>
                        <div className="col4 col-xs12 col" data-aos="fade-up" data-aos-duration="1500">
                            <div className="conctitem">
                                <div className="icon">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <h3>Location</h3>
                                <p>18, W.C. Banerjee Street,<br/>
                                    Kolkata – 700006,<br/>
                                    West Bengal,<br/>
                                    India</p>
                            </div>
                        </div>
                        <div className="col4 col-xs12 col" data-aos="fade-left" data-aos-duration="1500">
                            <div className="conctitem">
                                <div className="icon">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <h3>Location Also At</h3>
                                    <p>3rd Floor, Room No. 28<br/>
                                        40, Weston Street<br/>
                                        Kolkata - 700013<br/>
                                        West Bengal<br/>
                                        India</p>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        <section className="section conctfrm bg-blue">
            <div className="container">
                    <div className="row ">
                        <div className="col6 col-xs12 col" data-aos="fade-right" data-aos-duration="1500">
                            <div className="frmitem bg-white">
                                <div className="conctfrom">
                                <form action="submit.php" method="post" enctype="multipart/form-data" autocomplete="off">
                                    <div className="form-group inputWithIcon">
                                        <input type="text" className="form-control" name="name" id="name" placeholder="Name" required="" />
                                        
                                    </div>
                                    <div className="form-group inputWithIcon">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Email" required="" />
                                        
                                    </div>
                                    <div className="form-group inputWithIcon">
                                        <input type="number" className="form-control" name="phone" id="phone" placeholder="Phone" required="" />
                                        
                                    </div>
                                    <div className="form-group inputWithIcon">
                                        <textarea name="message" id="message" className="form-control" placeholder="Message" required=""></textarea>
                                        
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" name="contact" value="contact" className="btn sendbtn">Send</button>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                        <div className="col6 col-xs12 col" data-aos="fade-up" data-aos-duration="1500">
                        <div class="frmitem bg-white" >
                       

                        <Frame
                                url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7367.548507613356!2d88.364162!3d22.587545000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02764a9eff02af%3A0xf5199ecd72e5582b!2s18%2C%20W.C%20Banerjee%20St%2C%20Azad%20Hind%20Bag%2C%20Ram%20Bagan%2C%20Kolkata%2C%20West%20Bengal%20700006!5e0!3m2!1sen!2sin!4v1659789058560!5m2!1sen!2sin" 
                                width="100%"
                                height="100%" 
                                style={{border:0}} 
                                allowfullscreen="" 
                                loading="lazy" 
                                referrerpolicy="no-referrer-when-downgrade"
                               
                            >
                            </Frame>
                           </div>
                        </div>
                    </div>
            </div>
        </section>
    </>
  )
}
