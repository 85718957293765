import React, { useEffect } from 'react';
import AOS from 'aos';
import mediaImg from '../images/banner/page-banner-media.png';

export default function MediaEntertainment() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
    
    <section className="banner">
            <div className="bannerimg">
                <img src={mediaImg} className="img-responsive lazy" />
                <div className="bnr-ovrlay"></div>
            </div>
            <div className="page-title">
                <h2>MEDIA & ENTERTAINMENT</h2>
            </div>
        </section>
        <section className="section agreement-section-top clearfix">
            <div className="container clearfix">
        
            </div>
            <div className="row">
                <div className="col12 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                    data-aos-duration="1500">
                    <div className="media-intertainment">
                        <div className="container clearfix">
                            <div className="agreement-marks-main-services-text media-mark-main ">
                                <p>
                                    Our firm has a strong professional background in the Media and Entertainment
                                    landscape. We have advised clients on Film Rights Purchase Agreements and
                                    international Film Co-production Agreements.
                                </p>
                                <p>
                                    Our services include advising on the finer points of celebrity management and brand
                                    endorsements deals, engagement of performers such as singers, musicians etc. for
                                    live performances and other professional engagements, aside from negotiating various
                                    music and art licensing and assignment agreements.
                                </p>
                                <p>
                                    We have drafted and negotiated Advertising Production Agreements and advised on
                                    copyright protection of inter alia cartoon characters and adaptation of fictional
                                    characters for theme park decorations etc.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
