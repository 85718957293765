import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from 'react-router-dom';

const AppLayout = () => {


  return (
    <Fragment>
      <Header/>
        <Outlet />
      <Footer/>
    </Fragment>
  );
}

export default AppLayout;