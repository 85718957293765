import React, { useEffect } from 'react';
import AOS from 'aos';
import AGREEMENTS from '../images/banner/AGREEMENTS.png';

export default function Agreements() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
     <section className="banner">
            <div className="bannerimg">
                <img src={AGREEMENTS} className="img-responsive lazy" />
                <div className="bnr-ovrlay"></div>
            </div>
            <div className="page-title">
                <h2>AGREEMENTS & TRANSACTIONS</h2>
            </div>
        </section>
        <section className="section agreement-section-top clearfix">
            <div className="container clearfix">
                <div className="textinr agreement-title-text about-heading">
                   <h4>
                        (Our services cover the whole spectrum of IP related Agreements. The Agreements cited on this
                        page are merely representative).
                    </h4>
                    <div className="line"></div>
                </div>
                <div className="row ">
                    <div className="col6 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main agreememt-position-spaceing">
                            <div className="agreement-marks-main-services-text">
                                <h2>License Agreements </h2>
                                <div className="line agreement-line"></div>
                                <p>
                                    We specialise in drafting and negotiating a variety of license agreements and our
                                    team of Attorneys have successfully negotiated a number of deals, both as
                                    representatives of the licensor or the licensee. We have represented artists as well
                                    as businesses looking to license creative work and our Attorneys strive to ensure
                                    that both parties derive the most out of each deal.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col6 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main agreememt-position-spaceing">
                            <div className="agreement-marks-main-services-text">
                                <h2>Co-existence Agreements and Letters of Consent </h2>
                                <div className="line agreement-line"></div>
                                <p>
                                    Our Attorneys are skilled at drafting Co-existence Agreements and Letters of Consent
                                    based on negotiations in contested proceedings. Co-existence Agreements may, from
                                    time to time, involve complex questions regarding legal practices at different
                                    jurisdictions and conflicting business goals. Our skills lie in synthesizing the
                                    interests of both parties and reaching a mutually beneficial solution.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row agreement-border">
                    <div className="col6 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main agreememt-position-spaceing">
                            <div className="agreement-marks-main-services-text">
                                <h2>Brand Acquisition Agreements </h2>
                                <div className="line agreement-line"></div>
                                <p>
                                    ‘Brand Acquisition’, simply put, involves the purchase of a brand and other related
                                    rights by a business, offered for sale by another business. We advise clients on
                                    every aspect of acquiring a brand after conducting a thorough audit of the brand and
                                    draft and negotiate acquisition/assignment agreements, bearing in mind all along,
                                    the broader business goals of our clients.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col6 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main agreememt-position-spaceing">
                            <div className="agreement-marks-main-services-text">
                                <h2>Film Rights Purchase Agreements </h2>
                                <div className="line agreement-line"></div>
                                <p>
                                    We have experience in drafting and negotiating complex Film Rights Purchase
                                    Agreements. Our team has, in the past, successfully represented emerging filmmakers
                                    as well as film producers in rights purchase negotiations and keep abreast of latest
                                    developments in regional film industries and Bollywood.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row agreement-border">
                    <div className="col12 col-xs12 agreement-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                        <div className="design-marks-left-main agreement-text">
                            <div className="agreement-marks-main-services-text agreement-other">
                                <h2>Film Co-Production Agreements </h2>
                                <div className="line agreement-line"></div>
                                <p>
                                    Our Attorneys have advised Indian film producers and represented them in drafting and negotiating Co-production Agreements with producers from other jurisdictions such as Turkey and France.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </section>
    </>
  )
}
