import React  from 'react';
import logoWhite from '../images/logo-white.png';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"; 

export default function Header() {
  return (
    <header className="header hmhdr fixedtop main-menu">
    <nav className="navbar navbar-expand-lg navbar-light ">
      <div className="container contr">
        <div className="nav__logo"><Link className="navbar-brand" to="/"><img src={logoWhite} className="img-responsive lazy" /></Link></div>
        <button className="navbar-toggler custom-menu-toggle" >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse navbarSupportedContentBar" id="navbarSupportedContent">
          <ul className="navbar-nav margin-right mb-2 mb-lg-0">
            <li className="nav-item custom-menu">
              <Link className="nav-link active" aria-current="page" to="/index">Home</Link>
            </li>
            <li className="nav-item custom-menu">
              <Link className="nav-link" to="/about">About Us</Link>
            </li>
            <li className="nav-item custom-menu">
              <Link className="nav-link" to="/our-team">Our Team</Link>
            </li>
            <li className="nav-item dropdown custom-sub-menu">
              <Link className="nav-link dropdown-toggle" to="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Services
              </Link>
              <ul className="dropdown-menu default-menu custom-sub-ul-menu" aria-labelledby="navbarDropdown">
                <li className="ul-item custom-sub-ul-menus"><Link className="dropdown-item" to="/intellectual-property">Intellectual Property</Link></li>
                <li className="ul-item custom-sub-ul-menus"><Link className="dropdown-item" to="/litigation">Litigation & Alternate Dispute Redressal</Link></li>
                <li className="ul-item custom-sub-ul-menus"><Link className="dropdown-item" to="/agreements">Agreements & Transcations</Link></li>
                <li className="ul-item custom-sub-ul-menus"><Link className="dropdown-item" to="/domain">Domain Name Disputes</Link></li>
                <li className="ul-item custom-sub-ul-menus"><Link className="dropdown-item" to="/media-entertainment">Media & Entertainment</Link></li>
                <li className="ul-item custom-sub-ul-menus"><Link className="dropdown-item" to="/incorporetion">Incorporation & Registration</Link></li>
              </ul>
            </li>
            <li className="nav-item custom-menu">
              <Link className="nav-link " to="/testimonials">Testimonials</Link>
            </li>
            <li className="nav-item custom-menu">
              <Link className="nav-link " to="/contact">Contact Us</Link>
            </li>
            <li className="nav-item custom-menu">
              <Link className="nav-link " to="/career">Career</Link>
            </li>
          </ul>
          
        </div>
      </div>
    </nav>

     
  </header>


  )
}
