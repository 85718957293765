import React, { useEffect } from 'react';
import AOS from 'aos';
import LITIGATION from '../images/banner/LITIGATION.png';

export default function Iitigation() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
    
    <section className="banner">
            <div className="bannerimg">
                <img src={LITIGATION} className="img-responsive lazy" />
                <div className="bnr-ovrlay"></div>
            </div>
            <div className="page-title">
                <h2>LITIGATION AND ALTERNATE <br/>DISPUTE REDRESSAL</h2>
            </div>
        </section>
       

        <section className="section ligation-section clearfix">
            <div className="container clearfix">
          
                <div className="row ">

                    <div className="col12 col-xs12 litigation-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                            <div className="trade-marks-main-services-text">

                                <p>
                                    Our litigation services include all subject matters falling under the Intellectual
                                    Property umbrella such as Trade Marks, Patents, Designs, Copyrights, Trade Secrets
                                    and Confidential Information. We have extensive experience in litigating
                                    disparagement, infringement and passing off matters and also handle Writ Petitions
                                    and Civil Suits in matters pertaining to Competition Law, Law of Torts, Media &
                                    Entertainment Law, IT & E-commerce etc.
                                </p>
                                <p>
                                  We provide well-considered pre-litigation strategies in consultation with our
                                  clients keeping in mind their business objectives and cost considerations. We also
                                  handle appellate proceedings right up till the Supreme Court and our Attorneys have
                                  been successful in obtaining punitive damages in favour of our clients, injunctions
                                  and a variety of reliefs in a wide range of matters.
                              </p>
                              <p>
                                We also advocate and support Out-of-Court Settlements and Alternative Dispute Redressal (ADR) and have successfully represented our clients in a number of Arbitration matters.
                             </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
