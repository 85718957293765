import React, { useEffect } from 'react';
import AOS from 'aos';
import career from '../images/banner/career.png';

export default function Career() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
 
 <section className="banner">
            <div className="bannerimg">
                <img src={career} className="img-responsive lazy" />
                <div className="bnr-ovrlay"></div>
            </div>
            <div className="page-title">
                <h2>CAREER</h2>
            </div>
        </section>
       

        <section className="section about-section clearfix">
            <div className="container clearfix">
                <div className="textinr about-heading">
                    <h1 className="maintitle">Careers</h1>
                    <div className="line"></div>
                </div>
                <div className="row cnctrw">
                    <div className="col12 col-xs12 col aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="frmitem bg-blue">
                            <div className="conctfrom">
                            <form action="submit.php" method="post" enctype="multipart/form-data" autocomplete="off" className="row g-3">
                                
                                <div className="form-group inputWithIcon col-md-6">
                                    <input type="text" className="form-control" name="fname" id="fname" placeholder="First Name" required="" />
                                </div>
                                    
                                <div className="form-group inputWithIcon col-md-6">
                                    <input type="text" className="form-control" name="lname" id="lname" placeholder="Last Name" required="" />
                                </div>
                               
                                <div className="form-group inputWithIcon col-md-6">
                                    <input type="number" className="form-control" name="phone" id="phone" placeholder="Phone" required="" />
                                </div>
                                <div className="form-group inputWithIcon col-md-6">
                                    <input type="date" className="form-control" name="dob" id="dob" placeholder="Date Of Birth" required="" />
                                    
                                </div>
                                <div className="form-group inputWithIcon">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Email" required="" /> 
                                </div>
                               
                                <div className="form-group inputWithIcon">
                                    <textarea name="message" id="message" className="form-control" placeholder="Additional Information" required=""></textarea>
                                </div>
                                <div className="mb-3">  
                                    <label for="formFile" className="form-label">Upload Your CV</label>
                                    <input className="form-control" type="file" name="cv" id="formFile" />
                                  </div>

                                <div className="form-group">
                                    <button type="submit" name="carrer" value="carrer" className="btn submite-career">submit</button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
  )
}
