
import React from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"; 

export default function Footer() {
  return (
    <>
     <footer class="footer bg-blue">
      <div class="container-fluid footer-container">
        <div class="footer-nav">
          <ul class="ftrnavpgs">
            
            <li><a href="#" class="btn ftrbtn" data-target="simpleModal_1" data-toggle="modal">BOOK <br/>CONSULTATION</a></li>
          </ul>

          <ul class="socialmedia-ftr">
            <li><a href="https://www.linkedin.com/in/a-mukherjee-and-co-1597b3156" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="https://twitter.com/amukherjeeip?t=j5pwGf1O_lEQLnGptprA4w&s=08" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
            <li><a href="https://www.facebook.com/AMUKHERJEEIP/" target="_blank"><i class="fa fa-twitter"></i></a></li>
          </ul>
        </div>
      </div>
    </footer>
    <div id="fixed-float-sidebar">
<div className="alt-bg"><a target="_blank" href="https://www.linkedin.com/in/a-mukherjee-and-co-1597b3156" className="green-bg">
<i className="fa fa-linkedin"></i>
</a></div>
<div className="alt-responsive-space"> <a target="_blank" href="https://twitter.com/amukherjeeip?t=j5pwGf1O_lEQLnGptprA4w&s=08" className="green-bg">
<i className="fa fa-facebook-f"></i>
 </a> </div>
<div><a
  href="https://www.facebook.com/AMUKHERJEEIP/" className="whatsapp-bg" target="_blank"><i className="fa fa-twitter"></i></a> </div>
</div>

  <div id="popUpForm">
    <div id="popContainer">
     <div className="dsclmr-text">
      <h4>Disclaimer</h4>
      <p>The Bar Council of India does not permit advertisement or solicitation by advocates in any form or manner. By accessing this website, www.amukherjeeip.com, you acknowledge and confirm that you are seeking information relating to A. MUKHERJEE & CO of your own accord and that there has been no form of solicitation, advertisement or inducement by A. MUKHERJEE & CO or its members. The content of this website is for informational purposes only and should not be interpreted as soliciting or advertisement. No material/information provided on this website should be construed as legal advice. A. MUKHERJEE & CO shall not be liable for consequences of any action taken by relying on the material/information provided on this website. The contents of this website are the intellectual property of A. MUKHERJEE & CO.</p>
      <div id="close" className="btn closeDisclaimer">Accept</div> 
    </div>
    </div>
  </div>

    </>
  )
}
   