import React, { useEffect } from 'react';
import AOS from 'aos';
import information from '../images/banner/register-now-application-information-concept.jpg';

export default function IntellectualProperty() {
    useEffect(() => {
        AOS.init()
      },[])
  return (
    <>
    
    <section className="banner">
            <div className="bannerimg bg-blue">
                <div className="bannerbg"></div>
              
            </div>
            <div className="page-title">
                <h2>INTELLECTUAL PROPERTY</h2>
            </div>
        </section>
        
        <section className="section patentsec clearfix">
            <div className="container clearfix">
                <div className="textinr trade-marks-heading">


                    <h1 className="maintitle white">
                        PATENTS

                    </h1>
                    <div className="line bg-white"></div>
                </div>
            </div>

            <div className="patents-section patents-section">
                <div className="container clearfix">
                    <div className="row">

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>
                                        Searches and Opinions
                                    </h4>
                                    <p>
                                        Our Freedom to Operate (FTO), Patentability, Right-to-Use, Infringement and
                                        Validity
                                        opinions, based on searches carried out by our team of experienced
                                        professionals,
                                        using state of the art databases, pave the way for more effective and
                                        well-rounded
                                        business decisions.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>
                                        Patent Drafting and Filing
                                    </h4>
                                    <p>
                                        Effective drafting is at the core of every Patent Application. Our
                                        professionals,
                                        who have both technical and legal qualifications, are adept at preparing high
                                        quality Patent drafts including provisional, complete, divisional, and /or
                                        patents
                                        of addition, in all fields and industries.
                                        <br/>
                                        Our firm is able to file and handle all kinds of Patent applications at all
                                        Indian
                                        Patent Offices, as well as in international jurisdictions, through an extensive
                                        network of Associates in Europe, Asia, the Americas, the Middle East and
                                        Australia.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>
                                        Patent Prosecution
                                    </h4>
                                    <p>
                                        We are equipped to prosecute all kinds of Indian and international patent
                                        applications and place great emphasis on thorough pre-filing evaluation and
                                        effective argumentation, to ensure greater chances of success.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>
                                        Pre-Grant and Post-Grant Opposition
                                    </h4>
                                    <p>
                                        Patent opposition matters usually involve legal and technical questions of
                                        varying
                                        degrees of complexity. We handle both pre-grant and post-grant oppositions and
                                        our
                                        Attorneys are well capable of drafting compelling arguments that perfectly
                                        synthesize legal and technical matters.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>
                                        Revocation of Patent
                                    </h4>
                                    <p>
                                        With the abolition of the Intellectual Property Appellate Board (IPAB), the
                                        hon’ble
                                        High Courts are now empowered to inter alia hear Patent Revocation matters. Our
                                        Attorneys are adept at handling the technical and legal aspects of filing patent
                                        invalidation actions as well as defending against them.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>
                                        Patent Annuity Payments (Patent Renewal)
                                    </h4>
                                    <p>
                                        Patents are among a company’s most valuable intangible assets. We monitor
                                        portfolios
                                        systematically and provide cost effective patent renewal and restoration
                                        services in
                                        order to prevent these important assets from being abandoned.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4> Submission of Form 27</h4>
                                    <p>
                                        Our dedicated team of paralegals, under the supervision of our Patent Attorneys,
                                        file Form 27 (Statement Regarding the Working of Patented Invention (s) on a
                                        Commercial Scale in India) for our various clients, within the period stipulated
                                        by
                                        the Indian Patent Office.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="design-marks-left-main design-height-adjust">
                                <div className="trade-marks-main-services-text">
                                    <h4>Appeals</h4>
                                    <p>
                                        The rejection of a patent application is not the end of the road. The law inter
                                        alia
                                        provides for appeals to be filed against orders of the Controller of Patents &
                                        Designs before appropriate High Courts. We have the capability of handling
                                        complex
                                        appeal matters irrespective of the industry or technology they pertain to. We
                                        also
                                        handle appeals filed from the High Courts to the Supreme Court.
                                    </p>
                                </div>
                            </div>
                        </div>
                  </div>
                </div>
            </div>

        </section>


        <section className="section trade-marks-section clearfix">
            <div className="container clearfix">
                <div className="textinr trade-marks-heading">


                    <h1 className="maintitle trade-marks-title">TRADE MARKS
                    </h1>
                    <div className="line trade-marks-line"></div>
                </div>
            </div>
            <div className="trade-marks">
                <div className="container clearfix">
                    <div className="row">

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Pre-Filing Trade Mark Searches and Opinions
                                    </h4>
                                    <p>
                                        Conducting a Trade Mark search is an extremely important step before adopting a
                                        mark. A search reveals the availability of a Trade Mark with respect to the
                                        claimed
                                        goods and/or services and helps foresee possible obstacles to registration of
                                        the
                                        mark. We conduct in-depth Trade Mark searches for our Clients in India, as well
                                        as
                                        other jurisdictions (for foreign filings) and provide comprehensive opinions and
                                        advise on availability, use, filing and prosecution strategies.
                                    </p>
                                </div>
                            </div>


                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Filing Trade Mark Applications
                                    </h4>
                                    <p>
                                        We file each Trade Mark application after carefully considering the requirements
                                        of the client and based on findings of the pre-filing search and strategic
                                        selection of goods and/or services. We devise a possible prosecution strategy at
                                        the filing stage itself.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Trade Mark Prosecution
                                    </h4>
                                    <p>
                                        Trade Mark prosecution involves responding to and/or complying with the
                                        objections of the Registrar, attending and presenting arguments at hearings,
                                        securing publication of the mark and initiating or defending against third-party
                                        oppositions. Our team of seasoned Attorneys are well versed with submitting
                                        detailed and compelling arguments in response to Examination Reports and at
                                        hearings. With regard to foreign jurisdictions, we work closely with our
                                        extensive network of top international IP Attorneys.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Compliance with Provisional Refusal Notices with respect of the Madrid
                                        Applications Designating India
                                    </h4>
                                    <p>
                                        Our Attorneys draft extensive responses to Provisional Refusals issued by the
                                        Indian Trade Marks Registry with respect to International Registrations
                                        Designating India (IRDIs), attend prosecution hearings and advise clients on
                                        prosecution strategy taking into consideration directions of the Registrar.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Pre-Opposition Opinions and Trade Mark Oppositions
                                    </h4>
                                    <p>
                                        Our opposition strategies are based entirely on the client’s broader brand
                                        protection goals. For this reason, prior to filing an opposition in India or any
                                        other jurisdiction, we first present the client with an opinion comprising of
                                        the possible grounds, merits and likely risks involved, the information and
                                        documents required for filing the opposition and possible settlement strategies.
                                        We place special emphasis on the aspect of drafting the oppositions, in order to
                                        cover all possible grounds fully.
                                    </p>
                                </div>
                            </div>


                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Trade Mark Rectification/Cancellation
                                    </h4>
                                    <p>
                                        Like oppositions, we initiate rectification and/or cancellation proceedings
                                        based entirely on the client’s broader brand protection objectives. Our
                                        Attorneys have filed successful rectification and cancellation proceedings
                                        before the Trade Marks Registry, the Intellectual Property Appellate Board
                                        (previously) and the High Courts. Before any such proceedings are initiated, we
                                        inform our clients about the pros and cons of the said action and chances of
                                        success.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Trade Mark Renewal and Restoration
                                    </h4>
                                    <p>
                                        In India, registered Trade Marks are valid for 10 years, after which, they may
                                        be renewed any number of times for further 10 year periods. Non-renewal results
                                        in expunction of the mark from the Register. A lapsed Trade Mark may be restored
                                        after payment of the stipulated fee within a year’s time from the last renewal
                                        due date. We use a state-of-the-art IP Management software which helps us alert
                                        our clients about upcoming renewal deadlines as well as costs and requirements,
                                        well in advance, so that our client’s statutory rights are not lost due to
                                        non-renewal.
                                    </p>
                                </div>
                            </div>


                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Recordal of Change of Name/Address
                                    </h4>
                                    <p>
                                        When a Trade Mark owner undergoes change of name, corporate entity or address of
                                        registered office, relevant applications of recordal are required to be filed
                                        with the Trade Marks Registry to record such change. Our services include filing
                                        such relevant applications for recordal and following up with the Registry for
                                        taking such changes on record.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Appeals
                                    </h4>
                                    <p>
                                        With the abolition of the Intellectual Property Appellate Board (IPAB), the
                                        hon’ble High Courts are now empowered to hear and adjudicate appeals against the
                                        decision of the Registrar of Trade Marks. Our team of Attorneys have, in the
                                        past, successfully filed a number of appeals before the IPAB and more recently,
                                        before the High Courts, presented detailed arguments at appeal hearings and
                                        secured our client’s rights.
                                    </p>
                                </div>
                            </div>


                        </div>
                        <div className="col6 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                            <div className="trade-marks-left-main patents-height-adjust">
                                <div className="trade-marks-main-services-text"> <i className="fa fa-check"></i>
                                    <h4>
                                        Trade Mark Portfolio Management
                                    </h4>
                                    <p>
                                        We manage global as well as jurisdiction-specific Trade Mark portfolios of both
                                        our Indian and international clients. We offer strategic advisory based on our
                                        in-depth knowledge of IP laws and local practices of various jurisdictions. We
                                        work in consonance with our network of international associates in Europe, Asia,
                                        the Americas, the Middle East and Australia to ensure that our client’s rights
                                        are protected not only on home turf but also abroad.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <section className="section design-section clearfix">
            <div className="container clearfix">
                <div className="textinr about-heading">


                    <h1 className="maintitle white">DESIGNS
                    </h1>
                    <div className="line bg-white"></div>
                </div>


                <div className="row">

                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Searches & Opinions
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    We advise clients on registrability of Designs, Freedom To Operate (FTO) and
                                    infringement of Design rights based on comprehensive Prior Art and FTO searches
                                    conducted by our team of capable Attorneys. Our services also include advisory on
                                    Design infringement, piracy and invalidity of registered Designs, drawing from our
                                    vast experience in the field of Design law.
                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt="" />
                        </div>

                    </div>

                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Filing & Prosecution of Design
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    The new and original aesthetic features of an article – be it the shape of the
                                    article, patterns, composition of lines or colours applied to the article - is
                                    protected through Design registration. While filing a domestic or conventional
                                    Design application, we give special importance inter alia to the representation of
                                    the Design and its different views and clarity in describing the article on which
                                    the Design is to be used and its purpose etc.

                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt="" />
                        </div>

                    </div>

                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Design Prosecution
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    Our Attorneys attend to objections raised in examination reports as well as attend
                                    hearings before the Patent Office and have successfully secured grants of Design
                                    applications.
                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt="" />
                        </div>

                    </div>

                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Design Renewal and Post Registration Services
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    In India, registered industrial Designs are valid for 10 years from the date of
                                    registration or priority date. This initial period of registration may be extended
                                    by a further 5 years via an application made in Form-3 before the Controller of
                                    Patents & Designs, prior to the expiry of the 10 year term. We use state-of-the-art
                                    IP Management software which helps us alert our clients about upcoming renewal
                                    deadlines as well as costs and requirements, well in advance.
                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt=""/>
                        </div>

                    </div>


                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Design Cancellation
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    Our Attorneys methodically map out the pleadings and grounds of cancellation or
                                    rectification proceedings in consultation with clients and our technical team, at
                                    all stages of the proceedings.
                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt=""/>
                        </div>

                    </div>

                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Design Infringement Analysis
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    Prior to initiating any action for infringement of Design, it is important to first
                                    establish that the impugned Design is indeed deceptively similar to the registered
                                    Design. In doing so, reliance must be place on tests and standards laid down in case
                                    laws. Our team of Attorneys are well versed with such latest standards and tests and
                                    analyse each case of duplication methodically to determine Design infringement.

                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt=""/>
                        </div>

                    </div>

                    <div className="col12 col-xs12  aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <div className="patent-main-area-center design-bottom-space design-main-area-center">

                            <div className="patent-title-section">
                                <h2 className="white">
                                    Appeals
                                </h2>
                            </div>
                            <div className="patent-text-title-down">
                                <p>
                                    We have filed numerous appeals against Design refusal orders and orders passed in
                                    Design cancellation or rectification matters and enjoy a high rate of success.
                                </p>
                            </div>
                        </div>
                        <div className="list-number-section">
                            <img src="images/quote.png" alt=""/>
                        </div>

                    </div>

                </div>

            </div>
        </section>

        <section className="section copyright-section clearfix">
            <div className="container clearfix">
                <div className="textinr trade-marks-heading">
                    <h1 className="maintitle trade-marks-title">COPYRIGHT</h1>
                    <div className="line trade-marks-line"></div>
                </div>
            </div>
            <div className="copyright-main-section">
                <div className="container clearfix">
                    <div className="copyright-main-text">
                        <p>
                            Copyright law, over the years, has evolved into one of the most dynamic fields of law in the
                            global scenario. The advent of the Internet, digital platforms and social media, etc., have
                            revolutionised the legal landscape and technological awareness is a fundamental
                            pre-requisite for any IP Attorney.
                            Our firm comprises of tech savvy lawyers with extensive experience in copyright prosecution
                            and registration, licensing and assignment, contract drafting and negotiation, clearances
                            and regulatory compliances, dispute resolution and copyright enforcement. Additionally, we
                            have the capability of conducting anti-counterfeiting campaigns across a variety of
                            industries and drafting and negotiating a wide array of agreements such as art licensing
                            agreements and film rights purchase agreements.

                        </p>
                    </div>
                </div>
            </div>
        </section>  
         <section className="section GI-section  clearfix">
            <div className="container clearfix">
                <div className="textinr about-heading about-left-main">
                    <h1 className="maintitle white">GEOGRAPHICAL INDICATIONS</h1>
                    <div className="line bg-white"></div>
                </div>
                <div className="copyright-main-section about-left-main">
                    <div className="container clearfix">
                        <div className="copyright-main-text">
                            <p>A Geographical Indication (GI) is a sign used on products originating from a specific geographical location having qualities that are due to that origin. In order to function as a GI, a sign must identify a product as originating from a given geographical location. Some famous examples of registered Indian and international GIs are Darjeeling Tea, Tirupathi Laddu, Kangra Paintings, Nagpur Orange, Kashmiri Pashmina, Champagne, Tequila, Scotch Whiskey, Parmesan cheese etc.
                                <br/>Articles 1(2) and 10 of the Paris Convention for The Protection of Industrial Property, include indications of source and appellations of origin as aspects of industrial property protection. GIs are also covered under Articles 22 to 24 of the Trade Related Aspects of Intellectual Property Rights (TRIPS) Agreement. India, as a member of the World Trade Organization (WTO), enacted the Geographical Indications of Goods (Registration & Protection) Act, 1999 which came into force in 2003.
                                <br/>We have rendered opinions and advised Non-Governmental Organizations on filing and prosecution of GIs in India.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <section className="section trdscrt-section  clearfix">
                <div className="container clearfix">
                    <div className="textinr about-heading about-left-main">
    
    
                        <h1 className="maintitle white">TRADE SECRETS
                        </h1>
                        <div className="line bg-white"></div>
                    </div>
    
                    <div className="row">
                        <div className="col12 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                            data-aos-duration="1500">
                                <div className="trade-marks-main-services-text about-left-main">
    
                                    <p>According to the World Intellectual Property Organization (WIPO), a Trade Secret is confidential information which has a certain commercial value, is known to only a limited group of individuals and is subject to reasonable steps taken by its holder to keep it secret, owing to its high value. Perhaps the best known examples of Trade Secrets are the recipes of Coca-Cola and Kentucky Fried Chicken. Among other examples are the Google algorithm and the constituents of Listerine mouthwash.
                                       <br/>
                                       <br/>
                                        Trade Secrets clearly are vital to a business yet they are often difficult to define and India, till date, has no law protecting Trade Secrets.
                                        <br/>
                                        <br/> Our Attorneys, who come from diverse backgrounds, rely on their extensive experience in their individual fields to advise clients on some of the trickiest questions concerning Trade Secrets and confidential information. We draft a variety of documents to suit our client’s requirements such as Declaration of Confidentiality and Non-Disclosure and agreements such as Employment Agreements, Non-Disclosure Agreements, Technical Cooperation Agreements, Non-Compete and Non-Solicitation Agreements, aside from general advisory on the subject.
                                        
                                    </p>
                            </div>
    
                        </div>
    
                    </div>
    
    
                </div>
            </section>

         <section className="section ipcustom-section clearfix">
            <div className="container clearfix">
                <div className="textinr about-heading">


                    <h1 className="maintitle white">CUSTOMS PROTECTION</h1>
                    <div className="line bg-white"></div>
                </div>
                <div className="row">

                    <div className="col12 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-right"
                        data-aos-duration="1500">
                        <div className="about-left-main">
                            <p>
                                Counterfeit goods present serious health and safety risks for consumers aside from causing incalculable losses to any brand, including damage to its reputation.
                                We facilitate counterfeit investigation in domestic markets and advise clients about registering their intellectual property with the Central Board of Excise and Customs (now known as Central Board of Indirect Taxes & Customs (CBIC)), in order to monitor fakes coming into the country from abroad. We keep track of alerts received from the CBIC and assist in responding to notices and obtain orders for confiscation and destruction of counterfeit goods. 
                                
                            </p>
                        </div>
                    </div>

                </div>


            </div>
        </section>

            
        <section className="ip-audits section clearfix">
            <div className="container clearfix">
                <div className="textinr about-heading">
                    <h1 className="maintitle white">IP AUDITS AND DUE-DILIGENCE
                    </h1>
                    <div className="line bg-white"></div>
                </div>

                <div className="row">
                    <div className="col12 col-xs12 about-space-card aos-init aos-animate" data-aos="fade-up"
                        data-aos-duration="1500">
                            <div className="trade-marks-main-services-text">
                                <p>
                                    An IP audit is a systematic review of the IP owned, used and/or acquired by a
                                    business so as to assess and manage risks and address issues relating to validity,
                                    ownership, use and valuation.
                                <br/>
                                <br/>
                                    We carry out IP Audits and due-diligence for our clients by undertaking a
                                    comprehensive and thorough review of their IP assets, related agreements and
                                    relevant policies and give strategic advice as to optimum utilization of their IP.
                                    Our IP Audit Reports form part of M&A transactions, asset purchases and business
                                    transfers and a host of other functions.
                                </p>
                            </div>

                    </div>

                </div>


            </div>
        </section>
    </>
  )
}
